import React, { useEffect, useState } from 'react'
import './Clock.css'

const getTimeString = date => {
  let hours = date.getHours()
  let minutes = date.getMinutes()
  let seconds = date.getSeconds()

  minutes = minutes < 10 ? '0' + minutes : minutes
  seconds = seconds < 10 ? '0' + seconds : seconds

  return `${hours} : ${minutes} : ${seconds}`
}

const Clock = () => {
  const [time, setTime] = useState(getTimeString(new Date()))

  useEffect(() => {
    let intervalId = setInterval(() => tick(), 1000)

    return () => {
      clearInterval(intervalId)
    }
  })

  const tick = () => {
    setTime(getTimeString(new Date()))
  }

  return <div className="clock">{time}</div>
}

export default Clock
