import React, { useEffect, useState } from 'react'
import { FaTrashAlt } from 'react-icons/fa'
import './TodoItem.css'

const TodoItem = ({ todo, listid, handleDone, handleEdit, handleRemove }) => {
  const [todoValue, setTodoValue] = useState('')
  const [isInEditMode, setIsInEditMode] = useState(false)
  const [valueClass, setValueClass] = useState('value')

  const delay = 250
  let clickCount = 0
  let clickTimer = null

  useEffect(() => {
    if (isInEditMode) {
      document.getElementById('textbox').focus()
    }
  }, [isInEditMode])

  const handleDoubleClick = e => {
    e.preventDefault()

    setTodoValue(todo.value)
    toggleEditMode()
  }

  const handleClick = e => {
    e.preventDefault()

    clickCount++

    if (clickCount === 1) {
      clickTimer = setTimeout(() => {
        clickCount = 0
        setDone(todo.id, !todo.done)
      }, delay)
    } else if (clickCount === 2) {
      clearTimeout(clickTimer)
      clickCount = 0
      setTodoValue(todo.value)
      toggleEditMode()
    }
  }

  const handleChange = e => {
    setTodoValue(e.target.value)
  }

  const handleKeyDown = e => {
    if (e.keyCode !== 13 && e.keyCode !== 27) {
      return
    }

    handleEdit(listid, todo.id, todoValue)
    setTodoValue('')

    toggleEditMode()
  }

  const toggleEditMode = () => {
    setIsInEditMode(!isInEditMode)
  }

  const setDone = (id, done) => {
    handleDone(listid, id, done)
    setValueClass(todo.done === true ? 'value done' : 'value')
  }

  const renderEditMode = () => {
    return (
      <div className="todoitem">
        <input
          className="textbox"
          id="textbox"
          type="text"
          value={todoValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          autoFocus={true}
        />
      </div>
    )
  }

  const renderView = () => {
    return (
      <div draggable className="todoitem">
        <input
          className="checkbox"
          id="checkbox"
          type="checkbox"
          name="done"
          checked={todo.done}
          onChange={e => setDone(todo.id, e.target.checked)}
        />
        <div className={valueClass} onDoubleClick={handleDoubleClick}>
          {todo.value}
        </div>
        <button
          className="remove"
          onClick={() => handleRemove(listid, todo.id)}
        >
          <FaTrashAlt />
        </button>
      </div>
    )
  }

  return isInEditMode ? renderEditMode() : renderView()
}

export default TodoItem
