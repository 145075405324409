import React, { useEffect, useState } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import ProgressBar from '../ProgressBar'
import TodoItem from '../TodoItem'
import './TodoList.css'

const TodoList = ({
  todolist,
  todos,
  handleAdd,
  handleDone,
  handleEdit,
  handleRemove,
}) => {
  const [newTodo, setNewTodo] = useState('')
  const [showReverse, setShowReverse] = useState(false)
  const [todosClass, setTodosClass] = useState(`todos ${todolist.title}`)
  const [percentage, setPercentage] = useState(0)

  useEffect(() => {
    if (todos.length === 0) {
      setPercentage(0)
      return
    }

    let count = 0
    for (let i = 0; i < todos.length; ++i) {
      if (todos[i].done) {
        count++
      }
    }

    setPercentage((count / todos.length) * 100)
  }, [todos])

  const handleChange = e => {
    setNewTodo(e.target.value)
  }

  const handleKeyDown = e => {
    if (e.keyCode !== 13) {
      return
    }

    handleAdd(todolist.id, newTodo)
    setNewTodo('')
  }

  const handleShowReverseChange = e => {
    setShowReverse(e.target.checked)
    setTodosClass(
      e.target.checked === true
        ? `todos ${todolist.title} reverse`
        : `todos ${todolist.title}`
    )
  }

  return (
    <div className="todolist">
      <div className={todosClass}>
        <Scrollbars className="scrollbar">
          <div className="inner-scrollbar">
            <h1>{todolist.title}</h1>
            <ProgressBar
              color1={todolist.color1}
              color2={todolist.color2}
              percentage={percentage}
            />
            <input
              className="new-todo"
              placeholder="what needs to be done?"
              value={newTodo}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
            />
            <div className="todoitems">
              {todos.map(todo => (
                <TodoItem
                  todo={todo}
                  listid={todolist.id}
                  handleDone={handleDone}
                  handleEdit={handleEdit}
                  handleRemove={handleRemove}
                  key={todo.id}
                />
              ))}
            </div>
          </div>
        </Scrollbars>
      </div>
    </div>
  )
}

export default TodoList
