import anime from 'animejs/lib/anime.es.js'
import React, { useEffect, useState } from 'react'
import { FaCode } from 'react-icons/fa'
import Slider from 'react-slick'
import { CSSTransition } from 'react-transition-group'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import uuid from 'uuid'
import Clock from '../Clock'
import TodoList from '../TodoList'
import './App.css'

const App = () => {
  const todoLists = [
    {
      id: 0,
      title: 'Work',
      color1: '#6bb8e7',
      color2: '#545ddc',
    },
    {
      id: 1,
      title: 'Personal',
      color1: '#f0b456',
      color2: '#e94078',
    },
    {
      id: 2,
      title: 'Shopping',
      color1: '#38ef7d',
      color2: '#11998e',
    },
  ]

  const initialTodos0 = () => JSON.parse(localStorage.getItem('Todos-0')) || []
  const initialTodos1 = () => JSON.parse(localStorage.getItem('Todos-1')) || []
  const initialTodos2 = () => JSON.parse(localStorage.getItem('Todos-2')) || []

  const [currentBackgroundClass, setCurrentBackgroundClass] = useState(
    todoLists[1].title
  )
  const [nextBackgroundClass, setNextBackgroundClass] = useState(
    todoLists[1].title
  )
  const [todos0, setTodos0] = useState(initialTodos0)
  const [todos1, setTodos1] = useState(initialTodos1)
  const [todos2, setTodos2] = useState(initialTodos2)
  const [sliding, setSliding] = useState(false)

  const sliderSettings = {
    initialSlide: 1,
    dots: false,
    infinite: false,
    slidesToShow: 1,
    centerMode: true,
    centerPadding: '20%',
    arrows: false,
    focusOnSelect: true,
    speed: 500,
    beforeChange: (oldIndex, newIndex) =>
      handleSlideBeforeChange(oldIndex, newIndex),
    afterChange: index => handleSlideAfterChange(index),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          centerPadding: '10%',
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          centerPadding: '5%',
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          centerPadding: '20px',
          slidesToShow: 1,
        },
      },
    ],
  }

  useEffect(() => {
    anime({
      targets: `.${currentBackgroundClass} .todoitem`,
      opacity: [0, 1],
      translateX: [-250, 0],
      delay: anime.stagger(100),
      easing: 'easeOutCubic',
    })
  }, [])

  useEffect(() => {
    localStorage.setItem('Todos-0', JSON.stringify(todos0))
  }, [todos0])

  useEffect(() => {
    localStorage.setItem('Todos-1', JSON.stringify(todos1))
  }, [todos1])

  useEffect(() => {
    localStorage.setItem('Todos-2', JSON.stringify(todos2))
  }, [todos2])

  const handleSlideBeforeChange = (oldIndex, newIndex) => {
    setCurrentBackgroundClass(todoLists[oldIndex].title)
    setNextBackgroundClass(todoLists[newIndex].title)
    setSliding(true)

    anime({
      targets: `.${todoLists[oldIndex].title} .todoitem`,
      opacity: [1, 0],
      translateX: [0, -250],
      delay: anime.stagger(100),
      easing: 'easeOutCubic',
    })

    anime({
      targets: `.${todoLists[newIndex].title} .todoitem`,
      opacity: [0, 1],
      translateX: [-250, 0],
      delay: anime.stagger(100),
      easing: 'easeOutCubic',
    })
  }

  const handleSlideAfterChange = index => {
    setCurrentBackgroundClass(todoLists[index].title)
    setNextBackgroundClass(todoLists[index].title)
    setSliding(false)
  }

  const handleDone = (listid, id, done) => {
    let index = getTodoIndex(listid, id)
    let todo = getTodo(listid, id)
    todo.done = done

    switch (listid) {
      case 0:
      default:
        setTodos0([...todos0.slice(0, index), todo, ...todos0.slice(index + 1)])
        break
      case 1:
        setTodos1([...todos1.slice(0, index), todo, ...todos1.slice(index + 1)])
        break
      case 2:
        setTodos2([...todos2.slice(0, index), todo, ...todos2.slice(index + 1)])
        break
    }
  }

  const handleEdit = (listid, id, value) => {
    let index = getTodoIndex(listid, id)
    let todo = getTodo(listid, id)
    todo.value = value

    switch (listid) {
      case 0:
      default:
        setTodos0([...todos0.slice(0, index), todo, ...todos0.slice(index + 1)])
        break
      case 1:
        setTodos1([...todos1.slice(0, index), todo, ...todos1.slice(index + 1)])
        break
      case 2:
        setTodos2([...todos2.slice(0, index), todo, ...todos2.slice(index + 1)])
        break
    }
  }

  const handleRemove = (listid, id) => {
    let index = getTodoIndex(listid, id)
    switch (listid) {
      case 0:
      default:
        setTodos0([...todos0.slice(0, index), ...todos0.slice(index + 1)])
        break
      case 1:
        setTodos1([...todos1.slice(0, index), ...todos1.slice(index + 1)])
        break
      case 2:
        setTodos2([...todos2.slice(0, index), ...todos2.slice(index + 1)])
        break
    }
  }

  const getTodoIndex = (listid, id) => {
    switch (listid) {
      case 0:
      default:
        return todos0.findIndex(todo => todo.id === id)
      case 1:
        return todos1.findIndex(todo => todo.id === id)
      case 2:
        return todos2.findIndex(todo => todo.id === id)
    }
  }

  const getTodo = (listid, id) => {
    let index = getTodoIndex(listid, id)
    switch (listid) {
      case 0:
      default:
        return todos0[index]
      case 1:
        return todos1[index]
      case 2:
        return todos2[index]
    }
  }

  const handleAdd = (listid, newTodo) => {
    switch (listid) {
      case 0:
      default:
        setTodos0([
          ...todos0,
          {
            id: uuid.v4(),
            value: newTodo,
            done: false,
          },
        ])
        break
      case 1:
        setTodos1([
          ...todos1,
          {
            id: uuid.v4(),
            value: newTodo,
            done: false,
          },
        ])
        break
      case 2:
        setTodos2([
          ...todos2,
          {
            id: uuid.v4(),
            value: newTodo,
            done: false,
          },
        ])
        break
    }
  }

  return (
    <div className="app">
      <div id={'bg' + currentBackgroundClass} />
      <CSSTransition
        in={sliding}
        timeout={250}
        classNames="sliding"
        unmountOnExit
      >
        <div id={'bg' + nextBackgroundClass} />
      </CSSTransition>
      <header className="app-header">
        <h1>todo</h1>
        <Clock />
      </header>
      <div className="container">
        <Slider {...sliderSettings}>
          <TodoList
            todolist={todoLists[0]}
            todos={todos0}
            handleAdd={handleAdd}
            handleDone={handleDone}
            handleEdit={handleEdit}
            handleRemove={handleRemove}
          />
          <TodoList
            todolist={todoLists[1]}
            todos={todos1}
            handleAdd={handleAdd}
            handleDone={handleDone}
            handleEdit={handleEdit}
            handleRemove={handleRemove}
          />
          <TodoList
            todolist={todoLists[2]}
            todos={todos2}
            handleAdd={handleAdd}
            handleDone={handleDone}
            handleEdit={handleEdit}
            handleRemove={handleRemove}
          />
        </Slider>
      </div>
      <footer className="app-footer">
        <a href="https://bitschubse.de/">
          <FaCode /> by bitschubse
        </a>
      </footer>
    </div>
  )
}

export default App
