import React, { useEffect, useState } from 'react'
import './ProgressBar.css'

const ProgressBar = ({ color1, color2, percentage }) => {
  const [backgroundColor, setBackgroundColor] = useState('none')
  const [backgroundImage, setBackgroundImage] = useState('none')

  useEffect(() => {
    setBackgroundColor({ color1 })
    setBackgroundImage(`linear-gradient(350deg, ${color1} 0%, ${color2} 100%)`)
  })

  return (
    <div className="progressbar">
      <div
        className="filler"
        style={{
          width: `${percentage}%`,
          backgroundColor: `${backgroundColor}`,
          backgroundImage: `${backgroundImage}`,
        }}
      />
      <div className="text">{percentage.toFixed()} %</div>
    </div>
  )
}

export default ProgressBar
